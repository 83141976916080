import dashboard from './dashboard';
import facilities from './facilities';
import completeness from './completeness';
//import notifications from './notifications';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import samplePage from './sample-page';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, facilities, completeness]
};

export default menuItems;

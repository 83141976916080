import { IconFileUpload } from '@tabler/icons';

const facilities = {
  id: 'facilities',
  title: 'Uploads',
  type: 'group',
  icon: IconFileUpload,
  url: '/facilities'
};

export default facilities;

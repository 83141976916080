import { IconCircleCheck } from '@tabler/icons';

const completeness = {
  id: 'completeness',
  title: 'Completeness',
  type: 'group',
  icon: IconCircleCheck,
  url: '/completeness'
};

export default completeness;
